$(document).ready(function(){

    // opuźnia napisy na pierwszej stronie 
    $('#head').hide();
    $('#head').delay(2000).fadeToggle(1000);

    // opuźnia na podzakładkach
    $('.animation').hide();
    $('.animation').delay(500).fadeToggle(500);

    //menu 
    $('.sidenav').sidenav();

    //linki menu 
    $('.btn-tmt').on('click', function(){

        $('#tmt-menu').toggle(200);

    })
    $('.btn-kurs').on('click', function(){

        $('#kursy-tmt-menu').toggle(200);

    })


    //wyskakujące zdjęcia 
    $('.materialboxed').materialbox();
    // menu na komputer 
    $(".dropdown-trigger").dropdown();

    // okno modal 
    $('.modal').modal();

    // slider 
    $('.slider').slider();

    // karuzela 
    $('.carousel.carousel-slider').carousel({
        fullWidth: true,
        indicators: true
      });

});



       